export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.devices.text',
        icon: 'ri-server-line',
        subItems: [
            {
                id: 300,
                label: 'menuitems.devices.lists',
                link: '/device/list'
            },
            {
                id: 308,
                label: 'device.button.stockbind',
                link: '/device/stockbind'
            }
        ]
    },
    {
        id: 301,
        label: 'menuitems.devices.orders',
        icon: 'ri-list-ordered',
        link: '/device/orders'
    },
    {
        id: 9,
        label: 'menuitems.goods.text',
        icon: 'ri-shopping-bag-2-line',
        link: '/goods/list'
    },
    {
        id: 10,
        label: 'menuitems.admin.text',
        icon: 'ri-admin-line',
        link: '/admin/list'
    },
    {
        id: 11,
        label: 'menuitems.user.text',
        icon: 'ri-file-user-line',
        link: '/user/list'
    },
    {
        id: 13,
        label: 'menuitems.wechat.text',
        icon: 'ri-wechat-line',
        subItems:[
            {
                id: 1301,
                label: "menuitems.wechat.micoapp.text",
                link: '/wechat/micoapp'
            }
        ]
        
    },
    {
        id:14,
        label: 'menuitems.marketing.text',
        icon: 'ri-coupon-line',
        subItems: [
            {
                id: 1401,
                label: 'menuitems.marketing.wristband.text',
                link: '/marketing/wristband'
            }
        ]
    },
    {
        id: 8,
        label: 'menuitems.setting.text',
        icon: 'ri-settings-line',
        subItems: [
            {
                id: 800,
                label: 'menuitems.setting.account',
                link: '/setting/account'
            },
            {
                id: 802,
                label: 'menuitems.setting.commonly',
                link: '/setting/commonly'
            },
            {
                id: 803,
                label: 'menuitems.setting.system',
                link: '/setting/system'
            }
        ]
    },
    {
        id:15,
        label: "menuitems.clouds.text",
        icon: 'ri-cloud-line',
        subItems: [
            {
                id: 1500,
                label: 'menuitems.clouds.nfcreader.text',
                link: '/clouds/nfcreader'
            },
            {
                id: 1501,
                label: 'menuitems.clouds.printer.text',
                link: '/clouds/printer'
            },
            {
                id: 1502,
                label: 'menuitems.clouds.notify.text',
                link: '/clouds/notify'
            },
            {
                id: 1503,
                label: 'menuitems.clouds.payment.text',
                link: '/clouds/payment'
            }
        ]
    },
    {
        id: 16,
        label: 'menuitems.cashier.text',
        icon: 'ri-store-2-line',
        link: '/cashier'
    },
    {
        id: 12,
        label: 'menuitems.setting.initialization',
        icon: 'ri-file-info-line',
        link: '/initialization'
    },
    {
        id: 62,
        label: 'menuitems.setting.statistics',
        icon: 'ri-file-info-line',
        link: '/statistics'
    },
    {
        id: 99,
        label: 'about.text',
        icon: 'ri-flag-line',
        link: '/about'
    },
    {
        id: 18,
        isLayout: true
    }
]